import React from "react"
import CookiesVisualFS from "./CookiesVisualFS"

import { createOvermind } from 'overmind'
import { config } from '../../overmind/index'
import { Provider as OvermindProvider } from 'overmind-react'
import {RenderPlugin} from "../../components/Plugins/FiducPlugin";

const overmind = createOvermind(config, { devtools: false })

export const Index = ({pluginParams}) => (
	<OvermindProvider value={overmind}>
		<CookiesVisualFS pluginParams={pluginParams} />
	</OvermindProvider>
)

RenderPlugin('CookiesVisualFs', Index)



